/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.FormContainer {
  background-color: #ffffff;
  padding: 45px 20px 30px 20px;
}
@media (min-width: 768px) {
  .FormContainer {
    padding: 60px 40px 60px 40px;
    border-radius: 10px;
  }
}
@media (min-width: 1200px) {
  .FormContainer {
    padding: 60px 40px;
  }
}
@media (min-width: 1600px) {
  .FormContainer {
    padding: 80px 63px 70px 63px;
  }
}
