/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.Header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #353E40;
  padding-bottom: 20px;
  height: 90px;
}
@media (min-width: 768px) {
  .Header {
    height: 90px;
  }
}
@media (min-width: 1200px) {
  .Header {
    height: 90px;
  }
}
@media (min-width: 1600px) {
  .Header {
    height: 90px;
  }
}
@media (min-width: 768px) {
  .Header {
    background-color: transparent;
    padding-bottom: 0;
    position: relative;
    margin-bottom: -90px;
    z-index: 101;
    pointer-events: none;
  }
}
@media (min-width: 1200px) {
  .Header {
    position: relative;
    margin-bottom: -90px;
  }
}
@media (min-width: 1600px) {
  .Header {
    margin-bottom: 0;
  }
}
.Header::after {
  content: '';
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  width: 100%;
  height: 22px;
  background-color: #F3F4F3;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
}
@media (min-width: 768px) {
  .Header::after {
    display: none;
  }
}
.Popover {
  user-select: none;
  cursor: pointer;
}
.HeaderMenuOverlay {
  position: fixed;
  width: 100vw;
  height: calc(100vh - 48px);
  top: 48px;
  left: 0;
  z-index: -1;
}
.Container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  position: relative;
  pointer-events: none;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .Container {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .Container {
    padding: 0 60px;
  }
}
.LogoContainer {
  width: 150px;
  padding-left: 25px;
}
@media (min-width: 768px) {
  .LogoContainer {
    display: none;
  }
}
@media (max-width: 767px) {
  .UserDetailsContainer {
    top: 92%;
    left: 35px;
    z-index: 101;
    color: white;
    position: fixed;
  }
}
@media (max-width: 767px) {
  .SidebarIsOpen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
  }
}
