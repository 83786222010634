/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.PaddingWrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: var(--largeMargin);
}
@media (min-width: 768px) {
  .PaddingWrapper {
    padding: var(--mediumMargin);
  }
}
@media (max-width: 767px) {
  .PaddingWrapper {
    padding: var(--smallMargin);
  }
}
