/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.ComponentSpace {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  border-radius: 2px;
}
