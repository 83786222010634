/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.menuContainer {
  width: 300px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: red;
  display: flex;
  flex-direction: column;
  background-color: var(--thirdColor);
}
