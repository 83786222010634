/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.CreateOrderSummaryPage {
  flex-grow: 1;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .CreateOrderSummaryPage {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .CreateOrderSummaryPage {
    padding: 0 60px 60px 60px;
  }
}
@media (min-width: 1600px) {
  .CreateOrderSummaryPage {
    padding: 0 60px 60px 60px;
  }
}
.CreateOrderSummaryPage .ant-list-item-meta-title {
  padding-left: 25px;
}
.Title {
  margin-bottom: 0;
  height: 60px;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .Title {
    height: 90px;
  }
}
.SummaryDetailsList {
  padding-left: 0;
  list-style-type: none;
  line-height: 1.5;
}
.SummaryDetailsList li {
  margin-bottom: 12px;
}
.Container {
  background-color: #ffffff;
  padding: 20px;
}
@media (min-width: 768px) {
  .Container {
    padding: 35px;
    margin-right: 40px;
  }
}
@media (min-width: 1200px) {
  .Container {
    padding: 35px;
    border-radius: 10px;
  }
}
.ListItem {
  font-size: 14px;
}
@media (min-width: 768px) {
  .ListItem {
    font-size: 16px;
  }
}
