/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.UserPanel {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
.UserPanelOrganization {
  font-size: 0.8rem;
  margin: -5px 0 5px -5px;
}
.UserPanelDetails {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .UserPanelDetails {
    flex-direction: column;
  }
}
.UserPanelIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 10px;
  font-size: 2rem;
  color: white;
  margin-right: 30px;
}
.UserPanelText {
  display: flex;
  flex-direction: column;
}
.UserPanelTextLine {
  margin: 0px;
  font-size: 0.9rem;
}
.UserPanelMyAccount {
  font-size: 0.9rem;
  margin-top: 12px;
  color: #177e89;
}
.UserPanelMyAccount:hover {
  color: #177e89;
}
.UserPanelSignOut {
  font-size: 0.9rem;
  margin-top: 8px;
  color: red;
}
.UserPanelSignOut:hover {
  color: red;
}
