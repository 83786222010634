/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.AuthPage > * {
  background: white;
}
.AuthPage button {
  margin: 20px 0;
}
.LayoutRow {
  justify-content: center;
  align-items: stretch;
  min-height: 100vh;
}
.LayoutCol {
  justify-content: center;
  align-items: center;
  display: flex;
}
.LayoutCol:last-of-type {
  background: url('../../../assets/looping-bg.jpg') no-repeat center;
  background-size: cover;
  color: white;
  display: none;
}
@media (min-width: 768px) {
  .LayoutCol:last-of-type {
    display: flex;
  }
}
.ColumnContainer {
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  min-height: 70%;
  padding: 40px 0;
  position: relative;
}
@media (min-width: 768px) {
  .ColumnContainer {
    width: calc(100% - 60px);
    margin-left: 60px;
    margin-right: 60px;
    max-width: 450px;
    padding: 60px 0 30px 0;
  }
}
@media (min-width: 1600px) {
  .ColumnContainer {
    width: 100%;
  }
}
.ColumnContainer form {
  max-width: 385px;
}
.MainHeader {
  margin: 50px 0;
}
@media (min-width: 768px) {
  .MainHeader {
    margin-top: 60px;
  }
}
@media (min-width: 1600px) {
  .MainHeader {
    margin-top: 90px;
  }
}
.ListHeader {
  margin: 0 0 20px 0;
  font-weight: 400;
  color: white;
}
@media (min-width: 1200px) {
  .ListHeader {
    margin-top: 60px;
  }
}
@media (min-width: 1600px) {
  .ListHeader {
    margin-top: 90px;
  }
}
.TeaserList {
  margin: 70px 0 0;
  padding-left: 40px;
  list-style-type: none;
}
@media (max-width: 767px) {
  .TeaserList {
    margin: 40px 0 0;
  }
}
@media (min-width: 768px) {
  .TeaserList {
    padding-left: 50px;
  }
}
.TeaserList li {
  margin-bottom: 50px;
  font-weight: 400;
  position: relative;
  font-size: 16px;
}
@media (min-width: 1200px) {
  .TeaserList li {
    font-size: 18px;
  }
}
.TeaserList span {
  color: #80E0A7;
  transform: scale(1.5);
}
.Checked {
  position: absolute;
  left: -40px;
  top: 6px;
}
@media (min-width: 768px) {
  .Checked {
    left: -50px;
  }
}
.LogoContainer {
  width: 120px;
}
@media (min-width: 768px) {
  .LogoContainer {
    width: 185px;
  }
}
