/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #2A2B30;
  border-radius: 2px;
  margin-bottom: 30px;
}
.header {
  width: 100%;
  border-bottom: 1px solid #000000;
}
.header > h3 {
  margin: 0;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 700;
  color: var(#d9d9d9);
}
.contentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 20px;
  width: 100%;
}
