/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.StepsFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  height: 60px;
}
@media (min-width: 768px) {
  .StepsFooter {
    height: 90px;
  }
}
@media (min-width: 1200px) {
  .StepsFooter {
    height: 90px;
  }
}
@media (min-width: 1600px) {
  .StepsFooter {
    height: 90px;
  }
}
@media (min-width: 768px) {
  .StepsFooter {
    width: calc(100% - 250px);
    padding: 0 30px;
  }
}
@media (min-width: 1200px) {
  .StepsFooter {
    padding: 0 40px;
    filter: drop-shadow(0px 4px 60px rgba(146, 161, 164, 0.2));
  }
}
@media (min-width: 1600px) {
  .StepsFooter {
    width: calc(100% - 350px);
  }
}
.Steps {
  max-width: 40%;
  display: none !important;
}
@media (min-width: 768px) {
  .Steps {
    display: flex!important;
  }
}
.Step {
  cursor: pointer;
}
.DiscardButton {
  color: #80E0A7;
  border: none;
  outline: none;
  background: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
.DiscardIcon {
  width: 25px;
  height: 25px;
  border: 0;
  padding: 0;
}
.DiscardIcon span,
.DiscardIcon svg {
  width: 100%;
  height: 100%;
}
.DiscardIcon path {
  fill: #80E0A7;
}
