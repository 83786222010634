/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.SidebarLayout {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  justify-content: flex-end;
}
.Dashboard {
  width: 100%;
  min-height: 100vh;
}
@media (min-width: 768px) {
  .Dashboard {
    width: calc(100% - 250px);
  }
}
@media (min-width: 1600px) {
  .Dashboard {
    width: calc(100% - 350px);
  }
}
.Hamburger {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 25px;
  top: 18px;
  z-index: 101;
}
.Hamburger svg,
.Hamburger span {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .Hamburger {
    display: none;
  }
}
.Close {
  width: 33px;
  height: 33px;
  position: fixed;
  left: 25px;
  top: 18px;
  z-index: 101;
  border: 0;
  padding: 0;
}
.Close span,
.Close svg {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
  .Close {
    display: none;
  }
}
