/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.label {
  margin-right: 10px;
  white-space: nowrap;
  cursor: pointer;
}
.content {
  display: flex;
  align-items: center;
}
