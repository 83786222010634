/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.SubmitButton {
  border-radius: 50px;
  padding: 0 15px;
  height: 40px;
  min-width: 150px;
}
@media (min-width: 768px) {
  .SubmitButton {
    padding: 0 25px;
    height: 55px;
    min-width: 210px;
  }
}
@media (min-width: 1600px) {
  .SubmitButton {
    height: 60px;
    padding: 0 30px;
  }
}
.SubmitButton:last-child {
  margin-bottom: 0;
}
.SubmitButton svg path {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.SubmitButton:hover svg path,
.SubmitButton:focus svg path {
  fill: #ffffff;
}
