/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.FormPageFormatterView .largeForm .ant-select {
  width: 100%;
  height: 60px;
}
.FormPageFormatterView .largeForm .ant-select-selection {
  height: 100%;
  border-radius: 2px;
}
.FormPageFormatterView .largeForm .ant-select-selection__rendered {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
}
.FormPageFormatterView .largeForm .ant-select-selection__rendered:after {
  display: none;
}
.FormPageFormatterView .largeOption {
  height: 50px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.FormPageFormatterView .ant-tree.ant-tree-directory li .ant-tree-node-content-wrapper .ant-tree-title {
  color: var(#d9d9d9);
}
.FormPageFormatterView .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper:hover::before,
.FormPageFormatterView .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper:hover::before {
  background: #2A2B30;
}
