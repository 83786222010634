/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.RentalOrderDetailsPage {
  width: 100%;
  height: 100%;
}
.Header {
  margin: 0 8px 0 0;
}
.HeaderContainer {
  padding: 20px;
  margin-bottom: 0;
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
}
@media (min-width: 768px) {
  .HeaderContainer {
    padding: 20px 40px;
  }
}
@media (min-width: 1200px) {
  .HeaderContainer {
    padding: 20px 60px;
  }
}
@media (min-width: 1600px) {
  .HeaderContainer {
    padding: 40px 60px;
  }
}
@media (min-width: 768px) {
  .HeaderContainer {
    z-index: 100;
  }
}
.Options {
  background: #ffffff;
  padding: 20px;
}
@media (min-width: 768px) {
  .Options {
    padding: 20px 40px;
  }
}
@media (min-width: 1200px) {
  .Options {
    padding: 20px 60px;
  }
}
.Content {
  background: #ffffff;
  min-height: 100vh;
  padding: 20px;
  animation: fadeIn 0.3s ease forwards;
}
@media (min-width: 768px) {
  .Content {
    padding: 40px 40px 40px 40px;
  }
}
@media (min-width: 1200px) {
  .Content {
    padding: 60px 60px 60px 60px;
  }
}
@media (min-width: 1600px) {
  .Content {
    padding: 60px 60px 60px 60px;
  }
}
.Content .Content__inner {
  width: 100%;
}
.noTopPadding {
  padding-top: 0;
}
.Container {
  background-color: #ffffff;
  padding: 20px;
}
@media (min-width: 768px) {
  .Container {
    padding: 35px;
    margin-right: 40px;
  }
}
@media (min-width: 1200px) {
  .Container {
    padding: 35px;
    border-radius: 10px;
  }
}
.DetailsList {
  list-style-type: none;
  padding-left: 0;
}
.DetailsList li {
  margin-bottom: 10px;
}
.OrderStatus {
  margin: 0;
}
.Spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProductsList {
  max-width: 1000px;
}
.ProductsList td:first-of-type {
  min-width: 120px;
}
@media (min-width: 768px) {
  .ProductsList td:first-of-type {
    min-width: 200px;
  }
}
.LinkBackToList {
  margin-right: 8px;
}
@media (min-width: 768px) {
  .LinkBackToList {
    margin-right: 20px;
  }
}
@media (min-width: 1200px) {
  .LinkBackToList {
    display: none;
  }
}
.OrderDetails {
  margin-bottom: 20px;
}
.DeleteOrder {
  background-color: #B49A81;
  border: 0;
  color: #ffffff;
}
.DeleteOrder:hover,
.DeleteOrder:active {
  background-color: #7E6B58;
  color: #ffffff;
}
.DeleteOrder.GrayedOut:disabled,
.DeleteOrder.GrayedOut:hover,
.DeleteOrder.GrayedOut:active {
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
}
