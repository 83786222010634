/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.Spacer {
  margin: 20px 0;
}
@media (min-width: 768px) {
  .Spacer {
    margin: 30px 0;
  }
}
@media (min-width: 1600px) {
  .Spacer {
    margin: 40px 0;
  }
}
.Spacer:last-child {
  margin-bottom: 0;
}
