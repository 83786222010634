/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.StatusLabel {
  border-radius: 6px;
  border: 0;
  padding: 5px 18px;
  user-select: none;
  margin: 0;
}
.Brown {
  background: rgba(218, 204, 192, 0.4);
  color: #7E6B58;
}
.Green {
  background: rgba(128, 224, 167, 0.2);
  color: #23A56B;
}
.Blue {
  background: rgba(171, 204, 248, 0.2);
  color: #5474A0;
}
.Azure {
  background: rgba(190, 237, 237, 0.2);
  color: #1b7c7c;
}
.Grey {
  background: rgba(175, 176, 179, 0.2);
  color: #353E40;
}
