/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.UserDetailsContainer {
  pointer-events: all;
}
@media (max-width: 767px) {
  .UserDetailsContainer {
    bottom: 40px;
    left: 35px;
    z-index: 101;
    color: white;
    position: fixed;
  }
}
@media (min-width: 768px) {
  .UserDetailsContainer {
    z-index: 101;
  }
}
.UserDetails {
  align-items: center;
  flex-flow: row-reverse;
  cursor: pointer;
  pointer-events: all;
  display: none;
}
@media (min-width: 768px) {
  .UserDetails {
    display: flex !important;
  }
}
.UserDetailsText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.UserDetailsTextLine {
  display: flex;
  height: 20px;
  margin: 0 0 0 12px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
}
.SidebarOpen {
  display: flex !important;
}
