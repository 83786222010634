/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.Container {
  height: 55px;
  position: relative;
  display: none;
}
@media (min-width: 768px) {
  .Container {
    display: block;
  }
}
@media (min-width: 1600px) {
  .Container {
    height: 60px;
  }
}
.ButtonNewOrder {
  border-radius: 50px;
  padding: 0 25px;
  height: 55px;
  min-width: 190px;
  margin: 0;
  background-color: #546164;
  border: 0;
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.ButtonNewOrder:hover,
.ButtonNewOrder:active {
  background-color: #2A2B30;
  color: #ffffff;
}
@media (min-width: 768px) {
  .ButtonNewOrder {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    height: 55px;
    padding: 0 25px 0 65px;
  }
}
@media (min-width: 1600px) {
  .ButtonNewOrder {
    height: 60px;
    padding: 0 30px 0 60px;
  }
}
.ButtonNewOrder .ant-btn:focus > span {
  position: absolute;
}
.ButtonNewOrder span {
  font-size: 14px;
}
.ButtonNewOrder:hover,
.ButtonNewOrder:focus,
.ButtonNewOrder:active {
  background: #2A2B30;
  color: #ffffff;
}
.IconCreateOrder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 55px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #80E0A7;
}
@media (min-width: 1600px) {
  .IconCreateOrder {
    width: 60px;
  }
}
.IconCreateOrder svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
}
