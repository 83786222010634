/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.CreateOrderStep2Page {
  padding: 0 20px;
}
@media (min-width: 768px) {
  .CreateOrderStep2Page {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .CreateOrderStep2Page {
    padding: 0 60px 60px 60px;
  }
}
@media (min-width: 1600px) {
  .CreateOrderStep2Page {
    padding: 0 60px 60px 60px;
  }
}
.Title {
  margin-bottom: 0;
  height: 60px;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .Title {
    height: 90px;
  }
}
.Container {
  background-color: #ffffff;
}
@media (min-width: 768px) {
  .Container {
    margin-right: 40px;
    border-radius: 10px;
  }
}
.ListLoader {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProductName {
  margin-right: 10px;
}
