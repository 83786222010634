/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.Submitted {
  width: 100%;
  height: 100%;
}
.Submitted h1,
.Submitted p {
  max-width: 400px;
  text-align: center;
}
.Container {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  padding: 20px;
}
@media (min-width: 768px) {
  .Container {
    padding: 40px;
    margin: 90px 40px;
  }
}
@media (min-width: 1200px) {
  .Container {
    padding: 60px;
  }
}
@media (min-width: 1600px) {
  .Container {
    padding: 60px;
    margin: 0 40px;
  }
}
.OrderProgress {
  list-style-type: none;
  display: inline-flex;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}
@media (min-width: 768px) {
  .OrderProgress {
    min-width: 353px;
  }
}
.OrderProgress li {
  position: relative;
  margin-right: 20px;
  color: #92A1A4;
  font-size: 14px;
}
.OrderProgress li::after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: calc(100% + 5px);
  width: 10px;
  border: 0.5px solid #92A1A4;
}
@media (min-width: 768px) {
  .OrderProgress li::after {
    left: calc(100% + 10px);
    width: 20px;
  }
}
.OrderProgress li:first-of-type {
  color: #80E0A7;
}
.OrderProgress li:last-of-type {
  margin-right: 0;
}
.OrderProgress li:last-of-type:after {
  display: none;
}
