/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.Sticker {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  font-weight: 400;
}
@media (min-width: 1200px) {
  .Sticker {
    padding: 30px;
    max-height: 365px;
    max-width: 270px;
    margin-left: auto;
  }
}
.Sticker h1,
.Sticker h2,
.Sticker h3 {
  font-weight: 400;
}
.Sticker p {
  margin-bottom: 10px;
  line-height: 1.5;
}
.Sticker *:first-child {
  margin-top: 0;
}
.StickerBlue {
  background: #5474A0;
  color: #ffffff;
}
.StickerBlue h1,
.StickerBlue h2,
.StickerBlue h3,
.StickerBlue p {
  color: #ffffff;
}
.StickerGreen {
  background: #80E0A7;
  color: #2A2B30;
}
.StickerGreen h1,
.StickerGreen h2,
.StickerGreen h3,
.StickerGreen p {
  color: #2A2B30;
}
.StickerGrey {
  background: #546164;
  color: #ffffff;
}
.StickerGrey h1,
.StickerGrey h2,
.StickerGrey h3,
.StickerGrey p {
  color: #ffffff;
}
