/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.WasteSaved p {
  font-size: 12px;
  font-weight: normal;
  text-align: center;
}
.WasteSaved p.WasteSavedValue {
  margin-top: 40px;
  font-size: 36px;
}
