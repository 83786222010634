/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.CustomInputNumber {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  user-select: none;
  width: 100px;
  margin: 0 auto;
  /* hide spin buttons */
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.CustomInputNumber input::-webkit-outer-spin-button,
.CustomInputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.CustomInputNumber input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
}
.InputValue {
  font-size: 1em;
  font-weight: 500;
}
.InputValue::after {
  content: "";
  display: block;
  height: 2px;
  width: 50px;
  border-bottom: 1px solid #92A1A4;
  position: absolute;
  bottom: -5px;
  left: calc(50% - 25px);
}
.Icon {
  font-size: 1.4em;
  transform: scale(0.9);
}
.Icon svg path {
  fill: #23A56B;
}
@media (min-width: 1200px) {
  .Icon {
    transform: none;
  }
}
