/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.Container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.Text {
  font-size: 0.9rem;
  margin-left: 10px;
}
.Icon {
  font-size: 24px;
}
