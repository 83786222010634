/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-300px);
    box-shadow: none;
  }
  to {
    opacity: 1;
    transform: translateX(0);
    box-shadow: 12px 0 15px -4px rgba(0, 0, 0, 0.29);
  }
}
.RentalOrdersPage {
  display: block;
}
.RentalOrdersPageInPanelMode {
  display: flex;
}
@media (min-width: 1200px) {
  .RentalOrdersPageInPanelMode {
    padding-left: 310px;
  }
}
@media (min-width: 1600px) {
  .RentalOrdersPageInPanelMode {
    padding-left: 410px;
  }
}
.Content {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 0;
}
.ContentInPanelMode {
  display: none;
}
@media (min-width: 1200px) {
  .ContentInPanelMode {
    display: block;
    width: 310px;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 250px;
    background-color: white;
    animation: slideFromLeft 0.2s ease forwards;
  }
}
@media (min-width: 1600px) {
  .ContentInPanelMode {
    left: 350px;
    width: 410px;
    padding-top: 90px;
  }
}
.Header {
  padding: 20px;
  margin-bottom: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 768px) {
  .Header {
    padding: 20px 40px;
  }
}
@media (min-width: 1200px) {
  .Header {
    padding: 20px 60px;
  }
}
@media (min-width: 1600px) {
  .Header {
    padding: 40px 60px;
  }
}
@media (min-width: 768px) {
  .Header {
    height: 90px;
    justify-content: left;
  }
}
.LinkBackToList {
  padding: 20px;
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  height: 90px;
  line-height: 90px;
}
@media (min-width: 768px) {
  .LinkBackToList {
    padding: 20px 40px;
  }
}
@media (min-width: 1200px) {
  .LinkBackToList {
    padding: 20px 60px;
  }
}
@media (min-width: 1600px) {
  .LinkBackToList {
    padding: 40px 60px;
  }
}
@media (min-width: 768px) {
  .LinkBackToList {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1200px) {
  .LinkBackToList {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1600px) {
  .LinkBackToList {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 768px) {
  .LinkBackToList {
    height: 90px;
  }
}
@media (min-width: 1200px) {
  .LinkBackToList {
    height: 90px;
  }
}
@media (min-width: 1600px) {
  .LinkBackToList {
    height: 90px;
  }
}
.RentalOrdersList {
  outline: none;
}
.RentalOrdersListInPanelMode {
  height: 60vh;
}
.RentalOrdersListInPanelMode thead {
  height: 45px;
}
.RentalOrdersListInPanelMode thead th {
  padding: 20px 5px;
}
.ScrollContainer {
  overflow: auto;
}
.ScrollContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.ScrollContainer::-webkit-scrollbar-track {
  background: #d9d9d9;
}
.ScrollContainer::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}
.ScrollContainer::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}
.ScrollContainerNoFilters::before {
  content: '';
  display: block;
  height: 90px;
  background: rgba(146, 161, 164, 0.06);
  padding: 0 20px;
}
@media (min-width: 768px) {
  .ScrollContainerNoFilters::before {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .ScrollContainerNoFilters::before {
    padding: 0 60px;
  }
}
@media (min-width: 1600px) {
  .ScrollContainerNoFilters::before {
    padding: 0 60px;
  }
}
.DateSpan {
  width: 75px;
  display: inline-block;
  text-align: center;
}
.OrderNumberSpan {
  width: 50px;
  display: inline-block;
}
