/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.AntTable {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .AntTable {
    margin-bottom: 30px;
  }
}
@media (min-width: 1200px) {
  .AntTable {
    margin-bottom: 60px;
  }
}
@media (min-width: 1600px) {
  .AntTable {
    margin-bottom: 90px;
  }
}
.AntTable tbody tr:last-of-type td {
  border-bottom: 1px solid #f0f0f0;
}
