/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.InputIcon {
  font-size: 20px;
  margin: 0 3px 0 10px;
  padding: 5px;
  cursor: pointer;
}
.InputIconActive {
  background-color: #80E0A7;
  border-radius: 5px;
}
