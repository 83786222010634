/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.Sidebar {
  background-color: #353E40;
  width: 100%;
  position: fixed;
  min-height: 100vh;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 100;
  display: none;
  padding: 60px 30px 40px 30px;
}
@media (min-width: 768px) {
  .Sidebar {
    padding: 0 20px 40px 20px;
  }
}
@media (min-width: 1200px) {
  .Sidebar {
    padding: 0 20px 40px 20px;
  }
}
@media (min-width: 1600px) {
  .Sidebar {
    padding: 0 60px 60px 60px;
  }
}
@media (min-width: 768px) {
  .Sidebar {
    min-height: auto;
    width: 250px;
    min-width: 250px;
    display: flex !important;
    top: 0;
    left: 0;
  }
}
@media (min-width: 1600px) {
  .Sidebar {
    width: 350px;
    min-width: 350px;
  }
}
.LogoContainer {
  width: 130px;
  display: none;
  height: 90px;
}
@media (min-width: 768px) {
  .LogoContainer {
    display: flex;
    align-items: center;
    margin-left: 25px;
  }
}
@media (min-width: 1200px) {
  .LogoContainer {
    margin-left: 10px;
  }
}
@media (min-width: 1600px) {
  .LogoContainer {
    margin-left: 25px;
    margin-top: 0;
  }
}
.MenuContainer {
  width: 100%;
}
.Menu {
  background: #353E40 !important;
}
