/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.MobileOrderButton {
  position: absolute;
  right: 20px;
  top: calc(50% - 16px);
  z-index: 101;
  pointer-events: all;
}
.MobileOrderButton button {
  background-color: #546164;
  border: none;
  color: white;
  padding: 0 20px;
  border-radius: 50px;
}
@media (min-width: 768px) {
  .MobileOrderButton {
    display: none;
  }
}
.SidebarOpen {
  position: fixed;
  z-index: 1;
  right: 20px;
  top: 19px;
}
