/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
@media (min-width: 768px) {
  .FiltersBar {
    position: relative;
  }
}
.Container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: rgba(146, 161, 164, 0.06);
  padding: 0 20px;
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
@media (min-width: 768px) {
  .Container {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .Container {
    padding: 0 60px;
  }
}
@media (min-width: 1600px) {
  .Container {
    padding: 0 60px;
  }
}
@media (max-width: 767px) {
  .Container > div {
    flex: 1;
    display: flex;
  }
}
.FiltersBarPanelMode {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 768px) {
  .FiltersBarPanelMode {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1200px) {
  .FiltersBarPanelMode {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1600px) {
  .FiltersBarPanelMode {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.FiltersBarSearch {
  margin-left: auto;
  width: auto;
}
@media (max-width: 767px) {
  .FiltersBarSearch {
    margin-left: auto;
    max-width: 170px;
  }
}
@media (min-width: 768px) {
  .FiltersBarSearch {
    width: 200px;
  }
}
.FiltersBarSearchPanelMode {
  width: auto;
  flex-wrap: wrap;
}
.FilterFieldContainer {
  background: white;
  border-radius: 50px;
  height: 40px;
  padding: 0 6px;
  display: inline-flex;
  align-items: center;
}
@media (min-width: 768px) {
  .FilterFieldContainer {
    padding: 0 20px;
  }
}
.FilterField {
  color: #92A1A4;
  min-width: 160px;
}
.FiltersBarTimeRange {
  border: none;
}
.FiltersInfo {
  color: #23A56B;
  font-size: 14px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
@media (min-width: 768px) {
  .FiltersInfo {
    top: -34px;
    right: 40px;
  }
}
@media (min-width: 1200px) {
  .FiltersInfo {
    right: 60px;
  }
}
.FiltersInfoPanelMode {
  right: 20px;
}
.DiscardIcon svg path {
  fill: #23A56B;
}
