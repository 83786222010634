/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.CreateOrderStep1Page {
  flex-grow: 1;
  padding: 0 20px;
}
@media (min-width: 768px) {
  .CreateOrderStep1Page {
    padding: 0 40px;
  }
}
@media (min-width: 1200px) {
  .CreateOrderStep1Page {
    padding: 0 60px 60px 60px;
  }
}
@media (min-width: 1600px) {
  .CreateOrderStep1Page {
    padding: 0 60px 60px 60px;
  }
}
.Title {
  margin-bottom: 0;
  height: 60px;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .Title {
    height: 90px;
  }
}
.SubTitle {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.Alert {
  background-color: rgba(171, 204, 248, 0.2);
  border: 1px solid #ABCCF8;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  position: relative;
  top: -30px;
}
.Alert span {
  color: #5474A0;
}
.Checkbox {
  margin-bottom: 20px;
}
.FieldGroup {
  position: relative;
}
@media (min-width: 768px) {
  .FieldGroup {
    padding-right: 20px;
  }
}
.RadioGroup {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-flow: column wrap;
}
@media (min-width: 768px) {
  .RadioGroup {
    flex-flow: row wrap;
  }
}
.Required label {
  color: rgba(0, 0, 0, 0.85);
}
.Required label::after {
  display: block;
  content: ' *';
}
