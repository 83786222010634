/* Primary colors */
/* Secondary colors */
/* Notifications */
/* Old variables */
/* To remove */
/* Media queries */
/* Spacing */
/* Layers */
/* Overwrite antd theme 'default.less' variables */
/* Base */
/* Sidebar */
/* Forms */
/* Table */
/* Layout */
/* Header */
/* Steps footer */
/* Button */
/* Tabs */
.ErrorIndicator {
  color: red;
  font-size: 24px;
  margin: 0;
  padding-left: 5px;
  cursor: help;
  animation: error 1s ease-in-out alternate-reverse;
  animation-iteration-count: infinite;
}
@keyframes error {
  from {
    color: red;
  }
  to {
    color: yellow;
  }
}
